<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok', tax_rate_info) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="货品">
              <a-input v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="海关编码" >
              <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖单位" >
              <a-select
                v-decorator="[
                  'unit',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法定单位" >
              <a-select
                v-decorator="[
                  'legal_unit',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法定单位2" >
              <a-select
                v-decorator="[
                  'legal_unit2',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖单位与法定单位的比例" >
              <a-input
                v-decorator="['rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售卖单位与法定第二单位的比例" >
              <a-input
                v-decorator="['rate2', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="每单位净重(千克)" >
              <a-input
                v-decorator="['net_weight', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="每单位毛重(千克)" >
              <a-input
                v-decorator="['gross_weight', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="品牌、规格、型号" >
              <a-input
                v-decorator="['model', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="原产地" >
              <a-select
                v-decorator="[
                  'original_place',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="增值税" >
              <a-input
                addon-after="%"
                v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开启消费税" >
              <a-switch v-decorator="['open_consumption_tax', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openConsumptionTax"/>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="open_consumption_tax === 2">
            <a-form-item label="消费税单位">
              <a-select
                v-decorator="[
                  'consumption_tax_unit',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.consumption_tax_unit">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="open_consumption_tax === 2">
            <div>
              <a-button icon="plus" @click="handleAddTaxLevel" type="primary" style="margin-bottom: 5px;">
                添加
              </a-button>
              <a-table bordered :data-source="tax_rate_info.tax_level_list" :columns="tax_level_columns" :pagination="false">
                <template
                  v-for="col in ['min_price', 'max_price', 'consumption_tax_rate']"
                  :slot="col"
                  slot-scope="text, record"
                >
                  <div :key="col">
                    <a-input-number
                      v-if="record.editable"
                      style="margin: -5px 0"
                      :value="text"
                      @change="e => handleTaxLevelChange(e, record.key, col)"
                    />
                    <template v-else>
                      {{ text }}
                    </template>
                  </div>
                </template>
                <template slot="operation" slot-scope="text, record">
                  <div class="editable-row-operations">
                    <span v-if="record.editable">
                      <a @click="() => save(record.key)">保存</a>
                      <a-divider type="vertical" />
                      <a-popconfirm title="确定取消吗?" @confirm="() => cancel(record.key)">
                        <a>取消</a>
                      </a-popconfirm>
                    </span>
                    <span v-else>
                      <a :disabled="editingKey !== ''" @click="() => edit(record.key)">编辑</a>
                    </span>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      v-if="tax_rate_info.tax_level_list.length"
                      title="确定删除吗?"
                      @confirm="() => onDelete(record.key)"
                    >
                      <a href="javascript:;">删除</a>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'barcode', 'hs_code']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 11 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      fetching: false,
      goodsList: [],
      form: this.$form.createForm(this),
      tax_rate_info: {
        open_consumption_tax: this.open_consumption_tax,
        tax_level_list: []
      },
      cacheData: [],
      editingKey: '',
      tax_level_columns: [
        {
          title: '最小值',
          dataIndex: 'min_price',
          width: 100,
          scopedSlots: { customRender: 'min_price' }
        },
        {
          title: '最大值',
          dataIndex: 'max_price',
          width: 100,
          scopedSlots: { customRender: 'max_price' }
        },
        {
          title: '比例(%)',
          dataIndex: 'consumption_tax_rate',
          width: 100,
          scopedSlots: { customRender: 'consumption_tax_rate' }
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      open_self_life: false,
      goods_type: 1,
      open_consumption_tax: 1
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 当 model 发生改变时，为表单设置值
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.model)
    })
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.open_consumption_tax = 1
    this.consumption_tax_unit = 1
    this.tax_rate_info = {
      // open_consumption_tax: this.open_consumption_tax,
      consumption_tax_unit: this.consumption_tax_unit,
      tax_level_list: []
    }
  },
  methods: {
    openSelfLife (value) {
      console.log(value)
      this.open_self_life = value
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    goodsTypeChange (value) {
      this.goods_type = Number(value)
    },
    handleAddTaxLevel () {
      this.tax_rate_info.tax_level_list.push({
        key: this.tax_rate_info.tax_level_list.length + 1,
        min_price: 0,
        max_price: 0,
        consumption_tax_rate: 0.00,
        editable: false
      })
      this.cacheData = this.tax_rate_info.tax_level_list
    },
    handleTaxLevelChange (value, key, column) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.tax_rate_info.tax_level_list = newData
      }
    },
    edit (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.tax_rate_info.tax_level_list = newData
      }
    },
    save (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const newCacheData = [...this.cacheData]
      const target = newData.find(item => key === item.key)
      const targetCache = newCacheData.find(item => key === item.key)
      if (target && targetCache) {
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
      }
    },
    onDelete (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData.filter(item => {
          if (item.key !== key) {
            return item
          }
        })
      }
    },
    openConsumptionTax (value) {
      this.open_consumption_tax = value ? 2 : 1
      console.log(this.open_consumption_tax)
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        // const category = []
        // category.push(data.category.substr(0, 2))
        // category.push(data.category.substr(0, 4))
        // category.push(data.category.substr(0, 6))
        // data.goods_type = data.goods_type + ''
        // data.category = category
        const formData = pick(data, fields)
        this.id = formData.id
        // formData.shelf_life_unit = formData.shelf_life_unit + ''
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
